@import "../../../styles/global.scss";

.image-title-container {
  position: relative;

  width: 100%;

  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  background-color: $main-blue;

  border-radius: 16px;

  overflow: hidden;

  .background-waves {
    position: absolute;

    width: 100%;
    height: 100%;

    background-image: url("../../../assets/svg/Application/ImageTitle/Background.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: right bottom;
  }
}
