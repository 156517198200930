.no-graph-data-container {
  position: absolute;
  top: 50%;
  right: 50%;

  transform: translate(50%, -50%);

  display: flex;
  align-items: center;
  column-gap: 12px;

  span {
    font-size: 2rem;
  }

  img {
    width: 45px;
  }
}
