@import "../../../styles/global.scss";

@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&display=swap");

.logo-wrapper {
  padding: 20px;

  display: flex;

  img {
    width: 40px;
  }

  span {
    padding-top: 10px;

    color: $main-blue;

    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: "Instrument Serif", serif;
  }
}
