@import "../../../../styles/global.scss";

$text-color: rgb(29, 38, 48);

.image-title-content-container {
  padding: 48px;

  display: flex;
  justify-content: space-between;

  @media (max-width: $laptop) {
    padding: 32px;
  }

  @media (max-width: $mobile) {
    padding: 24px;
  }

  & > div:nth-child(1) {
    color: $text-color;
    z-index: 5;

    h1 {
      margin: 0;

      font-size: 2.25rem;
      letter-spacing: 1px;
      text-shadow: 1px 1px 2px $text-color;

      @media (max-width: $laptop) {
        font-size: 1.75rem;
      }

      @media (max-width: $mobile) {
        font-size: 1.5rem;
      }
    }

    p {
      margin: 6px 0 0 0;

      text-shadow: 0px 0px 1px $text-color;
      word-spacing: 1px;

      @media (max-width: $laptop) {
        font-size: 0.9rem;
      }
    }
  }

  & > div:nth-child(2) {
    z-index: 5;

    display: flex;
    align-items: center;

    @media (max-width: $mobile) {
      display: none;
    }

    img {
      margin-right: 64px;
      width: 100px;

      @media (max-width: $laptop) {
        width: 50px;
        height: 60px;
      }
    }

    & > img:nth-child(1) {
      transform: rotateY(180deg) translateX(-70px);
    }
  }
}
