@import "../../styles/global.scss";

.application-module {
  width: 100%;
  padding: 48px 24px;

  & > div {
    & > h1 {
      font-size: 2rem;
      color: $main-text-color2;

      @media (max-width: $mobile) {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: $mobile) {
    width: 90%;
    padding: 48px 0;
    margin: 0 auto;
  }
}
