@import "../../styles/global.scss";

.menu-container {
  width: 300px;
  height: 100vh;

  border-right: 1px dashed $border-color;

  @media (max-width: $laptop) {
    width: 175px;
  }
  @media (max-width: $mobile) {
    height: fit-content;
    width: 100%;
    padding-bottom: 16px;

    border-right: none;
    border-bottom: 1px dashed $border-color;
  }
}
