@import "../../../../styles/global.scss";

.bar-graphs-container {
  padding-bottom: 24px;

  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  border-bottom: 1px dashed $border-color;

  .bar-stats-container {
    padding: 24px;
    max-height: 300px;
    min-width: 300px;

    @include graphs-card;

    .title {
      margin-bottom: 16px;

      display: flex;
      align-items: center;
      column-gap: 8px;

      & > div {
        padding: 5px;

        display: flex;

        border-radius: 8px;

        img {
          width: 25px;
        }
      }

      & > h5 {
        margin: 0;

        font-size: 1.25rem;
        text-transform: uppercase;
      }
    }

    .bar-graph-wrapper {
      padding: 6px;
      height: 100px;
      background-color: $main-bg;

      border-radius: 15px;
    }
  }
}
