@import "../../../styles/global.scss";

.color-guide-lines-container {
  display: flex;
  column-gap: 16px;

  @media (max-width: $mobile) {
    column-gap: 8px;
    font-size: 0.85rem;
  }

  & > div {
    display: flex;
    align-items: center;
    column-gap: 4px;

    & > div {
      width: 10px;
      height: 10px;

      border-radius: 50%;
    }
  }
}
