//*********************************************** Font Themes **********************************************\\
@font-face {
  font-family: "Roboto Flex", sans-serif;
  src: url("../../public/RobotoFlex-Regular.ttf") format("trueType");
}

$main-font: "Roboto Flex", sans-serif;

//*********************************************** Color Themes **********************************************\\
$main-bg: rgb(19, 25, 32);
$main-blue: rgb(98, 147, 255);
$main-text-color: #8996a4;
$main-text-color2: #f8f9fade;
$border-color: rgb(62, 72, 83);

//*********************************************** Media **********************************************\\

$laptop: 1250px;
$mobile: 600px;

//*********************************************** Classes **********************************************\\
.graphs-container {
  margin-top: 32px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  column-gap: 48px;
  row-gap: 48px;

  @media (max-width: $laptop) {
    grid-template-columns: 1fr;
  }
  @media (max-width: $mobile) {
    gap: 24px;
  }
}

//*********************************************** Mixin **********************************************\\
@mixin graphs-card {
  background-color: rgb(29, 38, 48);
  box-shadow: 0 0 15px 5px rgb(26, 36, 48);

  border: 1px solid rgba(248, 249, 250, 0.05);
  border-radius: 12px;
}
