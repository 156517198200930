@import "../../../styles/global.scss";

.dashboard-container {
  margin-top: 8px;
  padding: 0 30px;

  @media (max-width: $laptop) {
    padding: 0 15px;
  }

  & > h5 {
    margin: 0;

    color: rgba(248, 249, 250, 0.45);

    font-size: 1rem;
    text-transform: uppercase;

    @media (max-width: $laptop) {
      font-size: 0.9rem;
    }
  }

  & > div {
    margin-top: 12px;
    padding-left: 12px;

    display: flex;
    flex-flow: column;
    row-gap: 4px;

    @media (max-width: $mobile) {
      flex-flow: row;
      row-gap: 0;
      gap: 8px;

      flex-wrap: wrap;
    }

    & > div {
      padding: 12px 0 12px 8px;

      display: flex;
      align-items: center;
      column-gap: 12px;

      border-radius: 15px;

      @media (max-width: $laptop) {
        padding: 6px 0 6px 6px;
        font-size: 0.8rem;
        border-radius: 8px;
      }

      @media (max-width: $mobile) {
        column-gap: 6px;
        padding: 6px;
      }

      img {
        width: 25px;

        @media (max-width: $laptop) {
          width: 15px;
        }
      }
    }

    .selected-dashboard-item {
      color: $main-blue;
      background-color: rgba(98, 147, 255, 0.16);

      font-weight: bold;
    }

    .not-selected-hover {
      &:hover {
        cursor: pointer;

        color: $main-blue;
        background-color: rgba(255, 255, 255, 0.05);

        font-weight: bold;
      }
    }
  }
}
