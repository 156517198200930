@import "./styles/global.scss";

body {
  margin: 0;
}

.app {
  width: 100%;
  height: 100%;

  display: flex;

  background-color: $main-bg;
  color: $main-text-color;

  font-family: $main-font;

  @media (max-width: $mobile) {
    flex-flow: column;
  }
}

// Scroll bar
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: $main-text-color;
}
::-webkit-scrollbar-thumb {
  background: rgb(56, 85, 148);
  border-radius: 25px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(39, 60, 105);
}
